import { Directive } from 'vue'
import { sessionStorage } from '@/utils/storage'

export const permission: Directive = {
    mounted(el, binding) {
        // value 获取用户使用自定义指令绑定的内容
        const { value } = binding
        // 获取用户所有的权限按钮
        const permissions = sessionStorage.get('permissions')
        // 判断用户使用自定义指令，是否使用正确了
        if (!permissions.includes(value)) {
            el.style.display = 'none'
        }
    }
}

export const isPermission = (permission) => {
    // 获取用户所有的权限按钮
    const permissions = sessionStorage.get('permissions')
    // 判断用户使用自定义指令，是否使用正确了
    return permissions.includes(permission)
}
