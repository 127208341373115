import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "重置密码",
    visible: _ctx.modalInfo.visible,
    onCancel: _ctx.onClose,
    onOk: _ctx.onSubmit
  }, {
    default: _withCtx(() => [
      (_ctx.modalInfo.visible)
        ? (_openBlock(), _createBlock(_component_a_form, {
            key: 0,
            ref: "formRef",
            model: _ctx.form,
            "label-col": { span: 5 },
            "wrapper-col": { span: 18 }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "新密码",
                name: "password",
                rules: [{ required: true, message: '请输入密码!', trigger: 'blur' }, {validator: _ctx.validatePass, trigger: 'change'}]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_password, {
                    value: _ctx.form.password,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                    placeholder: "请输入密码",
                    maxlength: 16
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["rules"]),
              _createVNode(_component_a_form_item, {
                label: "确认密码",
                name: "code",
                rules: [{ required: true, message: '请再次输入密码!', trigger: 'blur' }, {validator: _ctx.validatePass2, trigger: 'change'}]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_password, {
                    value: _ctx.form.code,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.code) = $event)),
                    placeholder: "请再次输入密码",
                    maxlength: 16
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["rules"])
            ]),
            _: 1
          }, 8, ["model"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "onCancel", "onOk"]))
}