import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './css/common.less'
import * as Icons from '@ant-design/icons-vue'
import { permission } from "@/utils/permission";
const app = createApp(App)
app.use(Antd).use(store).use(router)
const icons = Icons
for (const name in icons){
    app.component(name,(icons as any)[name])
}
// 添加到全局
app.config.globalProperties.$antIcons = Icons
app.directive('has', permission)
app.mount('#app')
