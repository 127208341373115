import { createStore } from 'vuex'
import { login } from '@/api/index'
import { storage, sessionStorage } from '@/utils/storage'

export default createStore({
  state: {
    user: {
      token: '',
      menus: [],
      index_page: ''
    },
    loading: 0, // 页面级loading
    loanOptions: []
  },
  getters: {
    token: state => state.user.token,
    menus: state => state.user.menus,
    index_page: state => state.user.index_page,
    loanOptions: state => state.loanOptions
  },
  mutations: {
    SET_USER: (state, user) => {
      const key = process.env.VUE_APP_COOKIE_KEY
      if (user.token) {
        storage.set(key, user)
        sessionStorage.set('permissions', user.permissions)
      } else {
        storage.remove(key)
        sessionStorage.remove('permissions')
      }
      state.user = user
    },
    changeModal (state: any, params) {
      state[params] = !state[params]
    },
    setLoading (state, active) {
      state.loading = active
    },
    setLoanOptions (state, active) {
      state.loanOptions = active
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.res_code === 200) {
            const result = response.data
            // Vue.ls.set('USER_INFO', result, 7 * 24 * 60 * 60 * 1000)
            commit('SET_USER', result)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout ({ commit }) {
      commit('SET_USER', {})
      window.location.reload()
    }
  },
  modules: {
  }
})
