import axios from 'axios'
import store from '@/store/index'
import { message, Spin } from 'ant-design-vue';
const pendingMap = new Map() // 请求池，存储请求
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000 // 请求超时时间
})


//获取请求的信息，进行记录
const getPendingKey = (config) => {
    const { url, method, params } = config
    let { data } = config
    if (typeof data === 'string') data = JSON.parse(data || '{}') // response里面返回的config.data是个字符串对象
    return [url, method, JSON.stringify(params), JSON.stringify(data || {})].join('&')//将url请求路径，method方法，params参数，data参数以&连接起来进行
}


//添加信息到请求池中
const addPending = (config) => {
    const pendingKey = getPendingKey(config)
    config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken((cancel) => {//axios 取消请求（axios中取消请求情况分为1：取消该请求之后的相同请求；2：取消该请求之前的相同请求）
            if (!pendingMap.has(pendingKey)) {
                pendingMap.set(pendingKey, cancel)
                store.commit('setLoading', pendingMap.size) // 记录请求池中请求的数量
            }
        })
}

const removePending = (config) => {
    const pendingKey = getPendingKey(config)
    if (pendingMap.has(pendingKey)) {
        const cancelToken = pendingMap.get(pendingKey)
        cancelToken(pendingKey)//如果请求过则取消请求
        pendingMap.delete(pendingKey)
        store.commit('setLoading', pendingMap.size)
    }
}

// request拦截器
service.interceptors.request.use(config => {
    removePending(config)
    const token = store.getters.token
    // 统一处理token
    if (token) {
        config!.headers!.Authorization = token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    addPending(config)
    return config
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        /**
         * code为非200是抛错 可结合自己业务进行修改
         */
        let res = typeof response.data === 'string' ? JSON.parse(response!.data) : response.data
        // store.commit('setLoading', false)
        removePending(response.config)
        if (response.status === 200) {
            if (res.res_code === 200) {
                return res
            } else {
                if (res.err_code.indexOf('TOKEN') > -1 || res.err_code.indexOf('INVALID_LOGIN') > -1) {
                    store.dispatch('Logout')
                }
                if (response && response.config && response.config.url && response.config.url.indexOf('finallySubmission') > -1 && res.res_code === 500) {
                    return Promise.reject(res)
                }
                message.warning(res.err_msg || '服务器繁忙，请稍后再试')
                return Promise.reject(res)
            }
        } else {
            message.warning(res.err_msg || '服务器繁忙，请稍后再试')
            return Promise.reject(res)
        }
    },
    error => {
        // store.commit('setLoading', false)
        removePending(error.config)
        if (error && error.response && error.response.status) {
            if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
                message.warning('网络异常，请检查网络后重试')
            } else {
                message.warning('网络异常，请稍后重试！')
            }
        } else {
            message.warning('网络未连接，请连接网络后重试')
        }
        return Promise.reject(error)
    }
)

export default service
