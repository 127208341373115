<template>
  <a-modal
      title="重置密码"
      :visible="modalInfo.visible"
      @cancel="onClose"
      @ok="onSubmit"
  >
    <a-form v-if="modalInfo.visible" ref="formRef" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="新密码" name="password"
                   :rules="[{ required: true, message: '请输入密码!', trigger: 'blur' }, {validator: validatePass, trigger: 'change'}]"
      >
        <a-input-password v-model:value="form.password" placeholder="请输入密码" :maxlength="16" />
      </a-form-item>
      <a-form-item label="确认密码" name="code"
          :rules="[{ required: true, message: '请再次输入密码!', trigger: 'blur' }, {validator: validatePass2, trigger: 'change'}]"
      >
        <a-input-password v-model:value="form.code" placeholder="请再次输入密码" :maxlength="16" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts">
import {defineComponent, reactive, ref, watch} from "vue";
import type { FormInstance } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form';
import { message } from "ant-design-vue";
import { isPassword } from '@/utils/util'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['visible-change', 'reset-submit'],
  setup(props, { emit }) {
    // modal管理
    const modalInfo = reactive({
      visible: false
    })
    const formRef = ref<FormInstance>()
    const form = reactive({
      password: '',
      code: ''
    })
    let validatePass = async (_rule: Rule, value: string) => {
      if (!isPassword(value)) {
        return Promise.reject("密码由8-16位字母、数字和字符组合,至少包含其中2种");
      } else {
        return Promise.resolve();
      }
    }
    let validatePass2 = async (_rule: Rule, value: string) => {
      if (value !== form.password) {
        return Promise.reject("两次输入密码不一致!");
      } else {
        return Promise.resolve();
      }
    }
    watch(
        () => modalInfo.visible,
        (visible) => {
          emit('visible-change', visible)
        }
    )
    watch(
        () => props.visible,
        (newVal, oldVal) => {
          if (!newVal) {
            formRef.value?.resetFields()
          }
          if (newVal !== oldVal) modalInfo.visible = newVal;
        },
        { deep: true }
    )

    /**
     * 取消按钮
     */
    const onClose = () => {
      modalInfo.visible = false
    }
    /**
     * 提交按钮
     */
    const onSubmit = async () => {
      try {
        const values = await formRef.value?.validateFields();
        emit('reset-submit', form.password)
        modalInfo.visible = false
      } catch (errorInfo) {
      }
    }

    return {
      modalInfo,
      formRef,
      form,
      onClose,
      onSubmit,
      validatePass,
      validatePass2
    }
  }
})
</script>
