import request from './request'

enum Api {
    dictHeader = '/sys/dict/type/one',
    dictItemHeader = '/sys/dict/item/one',
    deptHeader = '/sys/dept/one',
    permHeader = '/sys/perm/one',
    roleHeader = '/sys/role/one',
    userHeader = '/sys/user/one',
    resetPswHeader = '/sys/user/reset',
}

/**
 * 查找字典列表
 * @param params
 */
export const dictList = (params: any) => {
    return request({ url: '/sys/dict/type/list', method: 'post', data: params });
};

/**
 * 添加字典
 * @param params
 */
export const addDict = (params: any) => {
    return request({ url: Api.dictHeader, method: 'post', data: params });
};

/**
 * 修改字典
 * @param params
 */
export const updateDict = (params: any) => {
    return request({ url: Api.dictHeader + '/' + params.id, method: 'post', data: params });
};

/**
 * 删除字典
 * @param params
 */
export const deleteDict = (id: string) => {
    return request({ url: Api.dictHeader + '/' + id, method: 'delete' });
};

/**
 * 查找字典项列表
 * @param params
 */
export const dictItemList = (params: any) => {
    return request({ url: '/sys/dict/item/list', method: 'post', data: params });
};

/**
 * 添加字典项
 * @param params
 */
export const addItemDict = (params: any) => {
    return request({ url: Api.dictItemHeader, method: 'post', data: params });
};

/**
 * 修改字典项
 * @param params
 */
export const updateItemDict = (params: any) => {
    return request({ url: Api.dictItemHeader + '/' + params.id, method: 'post', data: params });
};

/**
 * 删除字典项
 * @param params
 */
export const deleteItemDict = (id: string) => {
    return request({ url: Api.dictItemHeader + '/' + id, method: 'delete' });
};

/**
 *批量获取字典
 * @param params
 */
export const dictView = (dict: any) => {
    return request({ url: '/sys/dict/view', method: 'post', data: dict });
};

/**
 * 查询部门列表
 * @param params
 */
export const deptList = () => {
    return request({ url: '/sys/dept/list', method: 'post' });
};

/**
 * 添加部门
 * @param params
 */
export const addDept= (params: any) => {
    return request({ url: Api.deptHeader, method: 'post', data: params });
};

/**
 * 修改部门
 * @param params
 */
export const updateDept = (params: any) => {
    return request({ url: Api.deptHeader + '/' + params.id, method: 'post', data: params });
};

/**
 * 删除部门
 * @param params
 */
export const deleteDept = (id: string) => {
    return request({ url: Api.deptHeader + '/' + id, method: 'delete' });
};

/**
 * 查询部门数
 * @param params
 */
export const deptTree = () => {
    return request({ url: '/sys/dept/tree', method: 'post' });
};

/**
 * 权限列表
 * @param params
 */
export const permList = (params: any) => {
    return request({ url: '/sys/perm/list', method: 'post', data: params });
};

/**
 * 添加权限
 * @param params
 */
export const addPerm = (params: any) => {
    return request({ url: Api.permHeader, method: 'post', data: params });
};

/**
 * 修改权限
 * @param params
 */
export const updatePerm = (params: any) => {
    return request({ url: Api.permHeader, method: 'put', data: params });
};

/**
 * 删除权限
 * @param params
 */
export const deletePerm = (code: string) => {
    return request({ url: Api.permHeader + '/' + code, method: 'delete' });
};

/**
 * 权限下拉框
 * @param params
 */
export const permTree = () => {
    return request({ url: '/sys/perm/view', method: 'get' });
};


/**
 * 角色列表
 * @param params
 */
export const roleList = (params: any) => {
    return request({ url: '/sys/role/list', method: 'post', data: params });
};

/**
 * 角色详情
 * @param params
 */
export const roleDetail = (id: string) => {
    return request({ url: Api.roleHeader + '/' + id, method: 'get' });
};

/**
 * 添加角色
 * @param params
 */
export const addRole = (params: any) => {
    return request({ url: Api.roleHeader, method: 'post', data: params });
};

/**
 * 修改角色
 * @param params
 */
export const updateRole = (params: any) => {
    return request({ url: Api.roleHeader + '/' + params.id, method: 'post', data: params });
};

/**
 * 删除角色
 * @param params
 */
export const deleteRole = (id: string) => {
    return request({ url: Api.roleHeader + '/' + id, method: 'delete' });
};

/**
 * 角色下拉
 * @param params
 */
export const roleSelect = () => {
    return request({ url: '/sys/role/view', method: 'get' });
};

/**
 * 用户列表
 * @param params
 */
export const userList = (params: any) => {
    return request({ url: '/sys/user/list', method: 'post', data: params });
};

/**
 * 用户详情
 * @param params
 */
export const userDetail = (id: string) => {
    return request({ url: Api.userHeader + '/' + id, method: 'get' });
};

/**
 * 添加用户
 * @param params
 */
export const addUser = (params: any) => {
    return request({ url: Api.userHeader, method: 'post', data: params });
};

/**
 * 修改用户
 * @param params
 */
export const updateUser = (params: any) => {
    return request({ url: Api.userHeader + '/' + params.id, method: 'post', data: params });
};

/**
 * 删除用户
 * @param params
 */
export const deleteUser = (id: string) => {
    return request({ url: Api.userHeader + '/' + id, method: 'delete' });
};

/**
 * 重置指定用户密码
 * @param params
 */
export const resetOne = (params: any) => {
    return request({ url: Api.resetPswHeader + '/' + params.id, method: 'post', data: params });
};

/**
 * 重置当前用户密码
 * @param params
 */
export const resetSelf = (params: any) => {
    return request({ url: Api.resetPswHeader, method: 'put', data: params });
};

/**
 * 获取当前用户专属链接
 * @param params
 */
export const getInviteLink = () => {
    return request({ url: '/getInviteLink', method: 'get' });
};



