<template>
  <a-layout>
    <a-layout-header class="header">
      <div class="logo flex-wrp" @click="toIndex">
        <img src="@/assets/logo.png" />
        <a-divider type="vertical" />
        <span>贷款管理平台</span>
      </div>
      <div class="hd-right">欢迎您，
        <a-popover placement="bottom">
          <a href="javascript:;">{{ $store.state.user.name }}</a>
          <template #content>
            <a-button type="text" @click="copyInvite">我的邀请码</a-button>
            <a-button type="text" @click="resetPsw">重置密码</a-button>
            <a-button type="text" @click="logout">退出登录</a-button>
          </template>
        </a-popover>
      </div>
      <menu-unfold-outlined @click="menuVisible = true" />
      <a-drawer v-model:visible="menuVisible" :width="200">
        <a-menu
            v-if="menuList && menuList.length > 0"
            v-model:selectedKeys="menuState.selectedKeys"
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
            @click="handleMenuClick"
            :openKeys="menuState.openKeys"
            @openChange="onOpenChange"
        >
          <template v-for="item in menuAct" :key="item.path">
            <a-menu-item v-if="!item.children" :key="item.path">
              <template #icon v-if="item.meta.icon"><component :is="$antIcons[item.meta.icon]" /></template>
              <span>{{ item.meta.label }}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="item.path" :title="item.meta.label ">
              <template #icon v-if="item.meta.icon"><component :is="$antIcons[item.meta.icon]" /></template>
              <a-menu-item v-for="_item in item.children" :key="_item.path">{{ _item.meta.label }}</a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
      </a-drawer>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="190" class="sider" theme="light" v-if="!$route.meta.hideSideBar">
        <a-menu
            v-if="menuList && menuList.length > 0"
            v-model:selectedKeys="menuState.selectedKeys"
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
            @click="handleMenuClick"
            :openKeys="menuState.openKeys"
            @openChange="onOpenChange"
        >
          <template v-for="item in menuAct" :key="item.path">
            <a-menu-item v-if="!item.children" :key="item.path">
              <template #icon v-if="item.meta.icon"><component :is="$antIcons[item.meta.icon]" /></template>
              <span>{{ item.meta.label }}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="item.path" :title="item.meta.label ">
              <template #icon v-if="item.meta.icon"><component :is="$antIcons[item.meta.icon]" /></template>
              <a-menu-item v-for="_item in item.children" :key="_item.path">{{ _item.meta.label }}</a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout style="margin: 60px 0px 0 191px" :style="{marginLeft: $route.meta.hideSideBar ? '0' : '191px'}" class="lay-content">
        <a-layout-content
            :style="{ padding: '0', margin: 0 }">
          <router-view v-slot="{ Component, route }">
            <keep-alive>
              <component :is="Component" :key="route.fullPath" />
            </keep-alive>
          </router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <reset-psw-modal :visible="pswModalVisible" @visible-change="pswModalVisibleChange" @reset-submit="resetPswApi"></reset-psw-modal>
  </a-layout>
</template>

<script lang="ts" setup>
import { defineComponent, reactive, toRefs, ref, watch, unref, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex'
import menuList from '@/router/menu'
import { storage } from '@/utils/storage'
import {getInviteLink, resetSelf} from "@/api/system";
import { copy } from "@/utils/util";
import {message} from "ant-design-vue";
import ResetPswModal from "@/components/user/resetPswModal.vue";
const store = useStore()
let route = useRoute()
let router = useRouter()
const { currentRoute } = useRouter()

const menuState = reactive({
  selectedKeys: ['/loan/apply'],
  openKeys: ['/loan']  // 默认展开的节点
});
const rootSubMenuKeys: string[] = [];
const useMenu = store.getters.menus
const menuAct = ref([])
try {
  for(let menu of menuList) {
    if (menu.children && menu.children.length > 0) {
      if (useMenu.includes(menu.name as string)) {
        menuAct.value.push(menu)
      } else {
        const menuItem =  menu.children.filter(menuItem => {
          return useMenu.includes(menuItem.name as string)
        })
        if (menuItem.length > 0) {
          let newMenu = menu
          newMenu.children = menuItem
          menuAct.value.push(newMenu)
        }
      }
    } else {
      if (useMenu.includes(menu.name as string)) menuAct.value.push(menu)
    }
  }
  for (const { children, path } of menuList) {
    if (children && children.length > 0) {
      rootSubMenuKeys.push(path);
    }
  }
} catch (e) {
  router.replace('/login')
}

handleMenuChange()

watch(() => route.path,val => {
      //当路由变化时，拿到当前路由所在模块的key
      if (val) {
        // menuState.selectedKeys = [val];
        handleMenuChange()
      }
    }
);

function onOpenChange(openKeys: string[]) {
  const latestOpenKey = openKeys.find(key => {
    return menuState.openKeys.indexOf(key) === -1
  });
  if (rootSubMenuKeys.indexOf(latestOpenKey!) === -1) {
    menuState.openKeys = openKeys;
  } else {
    menuState.openKeys = latestOpenKey ? [latestOpenKey] : [];
  }
}

async function handleMenuClick({ item, key }: { item: any; key: string; keyPath: string[] }) {
  await router.push(key)
  menuState.selectedKeys = [key];
}

function handleMenuChange() {
  const path = unref(currentRoute).path
  let r = path.split('/')
  menuState.openKeys = ['/' + r[1]]
  menuState.selectedKeys = [path]
}

const pswModalVisible = ref<boolean>(false)
function resetPsw() {
  pswModalVisible.value = true
}
function pswModalVisibleChange(e: boolean) {
  pswModalVisible.value = e
}
function resetPswApi(value: string) {
  resetSelf({
    password: value
  }).then(() => {
    message.success('重置密码成功！')
    logout()
  })
}

function logout() {
  store.dispatch('Logout')
}

const copyInvite = () => {
  getInviteLink().then(res => {
    copy(res.data)
    message.success('专属链接已复制！')
  })
}

const menuVisible = ref<boolean>(false)

function toIndex() {
  router.push('/')
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.logo {
  img {
    display: block;
    height: 30px;
  }
  cursor: pointer;
  .ant-divider {
    margin: 0 10px 0 12px;
    border-color: #ccc;
  }
  span {
    font-size: 16px;
  }
}
.ant-layout-header {
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
}
.sider {
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
:deep(.ant-menu-inline) {
  .ant-menu-item-selected {
    font-weight: 600;
  }
  .ant-menu-selected::after, .ant-menu-item-selected::after {
    display: none;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fff;
    padding: 0 10px;
    .ant-menu-item-selected {
      background: rgba(50, 112, 255, .08);
      border-radius: 3px;
    }
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background: rgba(50, 112, 255, .08);
  }
}
.ant-layout {
  min-height: calc(100vh - 68px);
}
h3 {
  margin: 40px 0 0;
}

.anticon-menu-unfold {
  line-height: 60px;
  font-size: 20px;
  display: none;
}
@media (max-width: 992px) {
  .anticon-menu-unfold {
    display: block;
  }
  .hd-right {
    display: none;
  }
  .ant-layout-sider {
    display: none;
  }
  .lay-content {
    margin-left: 0!important;
  }
}

</style>
